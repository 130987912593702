body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(to right, #0a0f0d, #1c3b57);
  color: white;
  overflow-x: hidden;
}

.slide-container {
  margin-top: 2%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slide-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.slide-container img {
  width: 40%;
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 60vh;
  cursor: pointer;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  z-index: 2;
}

.back-button {
  left: 26%;
}

.next-button {
  right: 26%;
}

.dots {
  text-align: center;
}

.dots .carousel__dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: grey;
  margin: 0 10px;
  border: none;
}

.dots .carousel__dot--selected {
  background-color: white;
}

.modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 600px;
  max-height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

@media screen and (max-width: 768px) {
  .slide-container img {
    width: 90%;
    max-height: 50vh;
  }

  .back-button {
    display: none;
  }

  .next-button {
    display: none;
  }
}

@media screen and (max-width: 899px) {
  .slide-container img {
    width: 70%;
    max-height: 500vh;
  }

  .carousel-button {
    top: 40%;
  }

  .back-button {
    left: 9%;
  }

  .next-button {
    right: 9%;
  }
}

@media screen and (max-width: 1250px) {
  .carousel-button {
    top: 41%;
  }
}
