.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: white;
  font-family: "Arial", sans-serif;
}

.profile-section img {
  text-align: center;
  margin-bottom: 2rem;
}

.profile-section h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.profile-picture {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.info-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
  row-gap: 1rem;
  max-width: 1200px;
}

.left-column,
.right-column {
  flex: 1;
  min-width: 300px;
}

.about-container h1 {
  font-size: 2.5rem;
  color: orange;
  margin-bottom: 0.5rem;
}

.about-container h2 {
  font-size: 1.8rem;
  color: orange;
  margin-bottom: 1rem;
  text-align: center;
}

.about-container p {
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 1rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .about-container p {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .about-container p {
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
  .about-container h2 {
    font-size: 1.5rem;
  }

  .about-container h1 {
    font-size: 2rem;
  }

  .info-section {
    width: 100%;
  }

  .left-column,
  .right-column {
    width: 100%;
    min-width: 200px;
  }
}
