.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-container {
  padding: 2rem;
  text-align: center;
  width: 90%;
  max-width: 750px;
  margin: auto;
}

.form-container h2 {
  margin-bottom: 1rem;
  color: orange;
  font-size: 32px;
}

.contact-container .form-container iframe {
  width: 100%;
  max-width: 700px;
  height: 1250px;
  border: none;
}

@media screen and (max-width: 768px) {
  .form-container {
    padding: 1rem;
  }

  .contact-container .form-container iframe {
    min-height: 500px;
    height: 1450px;
  }
}

@media screen and (max-width: 300px) {
  .form-container {
    padding: 0;
  }
}
