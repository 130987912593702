.logo {
  width: 100%;
  max-width: 450px;
}

.main-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  text-align: center;
}

.main-header h1 {
  font-size: 3.5rem;
  margin: 0.5rem 0;
  animation: homeFadeIn 2s ease-out 2s forwards;
  opacity: 0;
}

.main-header h2 {
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
  opacity: 0;
  animation: homeFadeIn 2s ease-out 4s;
  animation-fill-mode: forwards;
}

.typing-container {
  display: inline-block;
}

.typing-container p {
  display: inline-block;
  overflow: hidden;
  border-right: 0.15em solid orange;
  white-space: nowrap;
  letter-spacing: 0.15em;
  opacity: 0;
  animation: typing 2.5s steps(40, end), blink-caret 0.75s step-end infinite,
    homeFadeIn 0s forwards;
  animation-delay: 6s;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@keyframes homeFadeIn {
  from {
    opacity: 0;
    color: transparent;
  }
  to {
    opacity: 1;
    color: white;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    width: 100%;
    max-width: 300px;
  }

  .main-header h1 {
    font-size: 2.9rem;
    margin: 0.5rem 0;
    animation: homeFadeIn 2s ease-out 2s forwards;
    opacity: 0;
  }
}
